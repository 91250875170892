.upgrade-account {
    .prices-cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        padding: $mobileBodyPadding;

        .card {
            height: auto;
            padding: 30px 10px;
            display: flex;
            text-align: center;
            flex-direction: column;
            box-shadow: 0px 0px 1px rgb(0 0 0 / 5%), 0px 4px 32px rgb(58 53 47 / 5%);
            position: relative;
            border: 1px solid $primaryColor;

            .price {
                margin: 30px 0;
                font-weight: 400;

            }

            .feature-comp {
                font-size: 1.3rem;
                color: $primaryColor;
            }

            .features-list {
                width: 70%;
                margin: 30px auto 50px auto;

                li {
                    margin: 10px 0;
                    font-size: 0.9rem;
                    text-align: start;
                }
            }

            button {
                position: absolute;
                bottom: 10px;
                right: 0;
                left: 0;
                margin: 10px auto;
                width: 90%;
            }
        }
    }
}

@include for-phone-only {
    .upgrade-account {
        .prices-cards {
            padding: $smallestBodyPadding;
        }
    }
}

@include for-tablet-potrait-up {
    .upgrade-account {
        .prices-cards {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .upgrade-account {
        .prices-cards {
            grid-template-columns: repeat(4, 1fr);
            padding: 80px 100px;
        }
    }
}

@include for-big-desktop-up {}