@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "./variables";
@import "./mixins";

// COMPONENTS
@import '../components/navbar/navbar';
@import '../components/footer/footer';
@import '../components/loaders/loaders';
@import '../components/modals/modals';
@import '../components/wallet/wallet';
@import '../components/withdrawal/withdrawal-methods';


// PAGES
@import './home';
@import '../pages/about/about';
@import '../pages/services/services';
@import '../pages/support-center/support-center';
@import '../pages/sign-in/sign-in';
@import '../pages/sign-up/sign-up';
@import '../pages/terms-of-use/terms-of-use';
@import '../pages/privacy-policy/privacy';
@import '../pages/guides/guides';

// USER DASHBOARD
@import '../pages/dashboard/dashboard';
@import '../pages/dashboard/account';
@import '../pages/dashboard/upgrade-account';
@import '../pages/dashboard/deposit';
@import '../pages/dashboard/history';
@import '../pages/dashboard/withdrawal';




* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


html,
body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    background-color: #FFFFFF;
    color: $darkText;
}

a {
    color: inherit;
    text-decoration: none;
}


ul {
    list-style-type: none;
}


img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: center;
}

.border {
    border: 2px solid red;
}


// buttons

button {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
    padding: 12px 24px;
    cursor: pointer;
    transition: 0.2s linear opacity;
    color: $darkText;
    letter-spacing: 0.5px;

    &.blue {
        background: $primaryColor;
        color: #FFFFFF;
        border: none;

        &:hover {
            background-color: #66ace9;
            opacity: 0.9;
        }
    }

    &.grey {
        background: #EAECEF;
        color: $darkText;
        border: none;

        &:hover {
            background-color: #F5F5F5;
        }
    }

    &.red {
        background-color: #F03E3D;
        color: #FFFFFF;
        border: none;

        &:hover {
            background-color: #f86f6f;
            opacity: 0.9;
        }
    }

}


// CUSTOM INPUT FIELDS 

.form-control {
    label {
        margin-bottom: 10px;
        font-size: 16px;

        &.red {
            color: #F03E3D;
        }
    }

    input {
        margin-top: 10px;
    }
}

.input {
    width: 100%;
    height: 100%;
    border: 1px solid #D0CFCF;
    border-radius: 4px;
    background: $whiteColor;
    padding: 16px;
    font-size: 16px;
    outline: none;
    margin-top: 5px;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $whiteColor inset !important;
}


// RESET INPUT FONT-SIZE FOR MOBILE
@media screen and (max-width: 767px) {

    input,
    select,
    textarea {
        font-size: 16px;
    }
}


// COPY TO CLIPBOARD

.copy {
    padding: .1rem .5rem;
    border-radius: 5px;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: $generalTransition;

    &:hover {
        transform: scale(1.2);
    }

    &-btn {
        &.copied {
            color: $primaryColor;
        }
    }

}

@include for-tablet-potrait-up {
    .copy {
        position: absolute;
        right: 5px;
    }
}


// HIDE SCROLLBAR ON NUMBER INPUT
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}


// GOOGLE TRANSLATE

#google_translate_element {
    span {
        display: none !important;
    }

    select {
        border: 1px solid grey;
        padding: 0.5rem;
        border-radius: 5px;
    }

}

iframe {
    #options {
        display: none !important;
    }

    .VIpgJd-ZVi9od-l4eHX-hSRGPd {
        display: none !important;
    }

    td {
        &:first-child {
            border: 2px solid green;
        }
    }

    .VIpgJd-ZVi9od-LgbsSe {
        display: none !important;
    }
}