.deposit-history,
.withdrawal-history {
    padding: $mobileBodyPadding;
    min-height: calc(100vh - 105px);

    .history-block {
        width: 100%;
        height: 100%;
        box-shadow: $boxShadow;

        h1 {
            text-align: center;
            color: $primaryColor;
            font-size: 30px;
            padding-top: 20px;
        }

        .loader {
            margin: 20px auto 0 auto;
        }


        .table {
            padding: 20px;

            .table-data {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 20px;
                text-align: start;
                border-bottom: 2px solid gray;
                margin-bottom: 20px;
                padding-bottom: 10px;


                h4 {
                    font-weight: 600;
                    margin-bottom: 10px;
                }


                p {
                    font-weight: 500;
                }

                .pending {
                    color: #FFBF00;
                }

                .failed {
                    color: red;

                }

                .approved {
                    color: green;
                }
            }
        }

        .no-transaction {
            height: auto;
            padding: 30px 0;
            @include flexCC;
        }
    }
}

@include for-phone-only {

    .deposit-history,
    .withdrawal-history {
        padding: 20px 10px;

        .history-block {
            h1 {
                font-size: 25px;
            }

            .table {
                padding: 10px;
            }
        }
    }
}

@include for-tablet-potrait-up {

    .deposit-history,
    .withdrawal-history {
        .history-block {
            h1 {
                font-size: 35px;
            }

            .table {
                padding: 20px;

                .table-data {
                    grid-template-columns: repeat(3, 1fr);


                }
            }
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {

    .deposit-history,
    .withdrawal-history {
        padding: $desktopBodyPadding;

        .history-block {
            h1 {
                font-size: 35px;
            }

            .table {
                padding: 20px;

                .table-data {
                    display: grid;
                    grid-template-columns: repeat(6, 1fr);
                    grid-gap: 20px;
                    text-align: center;
                    border-bottom: 2px solid gray;
                }
            }
        }
    }

    .deposit-history {
        .history-block {
            .table {
                .table-data {
                    grid-template-columns: repeat(5, 1fr);
                }
            }
        }
    }
}

@include for-big-desktop-up {}