.successful-registration,
.successful-reset,
.withdrawal-request,
.verify-account,
.notification,
.update-info {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    @include flexCC;
    padding: $mobileBodyPadding;
    z-index: 66;

    .modal {
        background: #ffffff;
        height: auto;
        padding: 40px 20px;
        @include flexCC;
        flex-direction: column;
        border-radius: 10px;
        text-align: center;

        .img {
            position: relative;
            height: 100px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        h2 {
            font-size: 20px;
            margin: 20px;
            color: $primaryColor;
        }

        .seed-phrase {
            border: 1px solid $primaryColor;
            padding: 20px 10px;
            margin: 20px 0;
            width: 100%;
            @include flexCC;
            position: relative;
        }

        .verify-msg {
            line-height: 24px;
            font-weight: 400;
        }

        button {
            width: 140px;
            margin-top: 10px;
        }

        .warning {
            color: #ea435c;
            font-weight: 500;
            line-height: 26px;
        }
    }
}

.withdrawal-request {
    p {
        line-height: 28px;

        strong {
            font-weight: 700;
        }
    }

    .warning {
        background: #ea435c;
        padding: 1rem;
        color: #ffff !important;
    }
}

.update-info {
    .modal {
        align-items: flex-start;
        text-align: start;
        width: 100%;


        form {
            width: 100%;
            margin-top: 10px;

            .form-control {
                margin-bottom: 10px;

                label {
                    font-weight: 400;
                }
            }

            .btns {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;

                .submit-btn {
                    @include flexCB;
                    position: relative;
                    width: 50%;

                    button {
                        margin-top: 0;
                        margin-right: 5px;
                    }
                }

                .cancel-btn {
                    width: 120px;
                    margin-top: 0;
                }
            }


        }

        h2 {
            text-align: center;
            margin: 10px auto;
        }
    }
}



@include for-phone-only {
    .successful-registration {
        padding: $smallestBodyPadding;

        .modal {
            padding: 20px 20px;

            .img {
                height: 50px;
                width: 50px;
            }

            h2 {
                font-size: 20px;
                margin: 10px 0;
            }
        }


    }


}

@include for-tablet-potrait-up {

    .successful-registration,
    .successful-reset,
    .withdrawal-request,
    .verify-account,
    .notification,
    .update-info {
        .modal {
            width: 60%;
        }
    }

    .withdrawal-request {
        .modal {
            width: 80%;
        }
    }

    .update-info {
        .modal {
            width: 60%;
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {

    .successful-registration,
    .successful-reset,
    .withdrawal-request,
    .verify-account,
    .notification {
        .modal {
            max-width: 500px;

            button {
                width: auto;
                padding: 10px 40px;
            }
        }
    }

    .update-info {
        .modal {
            width: 30%;
        }
    }
}

@include for-big-desktop-up {}