.privacy {
    padding: $mobileBodyPadding;

    .block {
        margin-bottom: 30px;

        h2 {
            color: $primaryColor;
            font-weight: 700;
            font-size: 23px;
            margin-bottom: 10px;
        }


        p {
            line-height: 30px;
        }
    }

}

@include for-phone-only {}

@include for-tablet-potrait-up {
    .privacy {
        .block {
            margin-bottom: 50px;

            h2 {
                font-size: 28px;
                margin-bottom: 15px;
            }


        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .privacy {
        padding: $desktopBodyPadding;
    }
}

@include for-big-desktop-up {}